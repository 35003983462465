import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseDialogIcon } from '../base-dialog/base-dialog-icon.enum';
import { BaseDialogResponse } from '../base-dialog/base-dialog-response.enum';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';

export type InfoDialogData = {
  title: string;
  message: string;
  width: string;
  height: string;
  icon: BaseDialogIcon;
  details?: string[];
  confirmBtnText?: string;
};

@Component({
  selector: 'app-job-status-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss'],
})
export class InfoDialogComponent extends BaseDialogComponent<InfoDialogComponent> {
  constructor(
    dialogRef: MatDialogRef<InfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InfoDialogData,
  ) {
    super(dialogRef);
  }

  public onConfirmClick(): void {
    this.close(BaseDialogResponse.CONFIRM);
  }
}
