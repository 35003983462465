import { createFeatureSelector, createSelector } from '@ngrx/store';
import { projectsFeatureKey, ProjectsState } from './projects.reducer';

const selectProjectsState =
  createFeatureSelector<ProjectsState>(projectsFeatureKey);

export const selectCurrentProjectId = createSelector(
  selectProjectsState,
  (state: ProjectsState) => state.currentProjectId,
);
