import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseDialogIcon } from '../base-dialog/base-dialog-icon.enum';
import { BaseDialogResponse } from '../base-dialog/base-dialog-response.enum';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';

export type BasicDialogData = {
  title: string;
  message: string;
  icon?: BaseDialogIcon;
  width: string;
  height: string;
  continueBtnText?: string;
  cancelBtnText?: string;
};

/**
 * Title and message information is passed using the MAT_DIALOG_DATA
 * When opening this dialog title and messages should be passed as dialogOptions
 */
@Component({
  selector: 'app-basic-confirm-dialog',
  templateUrl: './basic-confirm-dialog.component.html',
  styleUrls: ['./basic-confirm-dialog.component.scss'],
})
export class BasicConfirmDialogComponent extends BaseDialogComponent<BasicConfirmDialogComponent> {
  constructor(
    dialogRef: MatDialogRef<BasicConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BasicDialogData,
  ) {
    super(dialogRef);
  }

  public onCancelClick(): void {
    this.close(BaseDialogResponse.CANCEL);
  }

  public onContinueClick(): void {
    this.close(BaseDialogResponse.CONFIRM);
  }
}
