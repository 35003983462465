import { Injectable } from '@angular/core';
import {
  CLIENT_CALIBRATION_API_VERSION,
  REPORTING_API_VERSION,
  SAMA_GO_API_VERSION,
  SAMA_PROJECTS_API_VERSION,
  SAMA_TASKS_API_VERSION,
} from '@sama/common';
import { InterceptorUrlsService } from '@sama/ngx-components';

@Injectable({
  providedIn: 'root',
})
export class GoInterceptorUrlsService implements InterceptorUrlsService {
  constructor() {}

  public getUrls(): string[] {
    return [
      `${PORTAL_CONFIG.backendBaseUrl}/${SAMA_GO_API_VERSION}/`,
      `${PORTAL_CONFIG.samahubUrl}/api/v2`,
      `${PORTAL_CONFIG.featureFlags.backendBaseUrl}`,
      `${PORTAL_CONFIG.samaProjectsUrl}/api/${SAMA_PROJECTS_API_VERSION}`,
      `${PORTAL_CONFIG.samaTasksUrl}/api/${SAMA_TASKS_API_VERSION}`,
      `${PORTAL_CONFIG.clientCalibrationUrl}/api/${CLIENT_CALIBRATION_API_VERSION}`,
      `${PORTAL_CONFIG.samplingBackendBaseUrl}/v1`,
      `${PORTAL_CONFIG.accountsBackendUrl}/api`,
      `${PORTAL_CONFIG.reporting.baseUrl}/${REPORTING_API_VERSION}`,
      `${PORTAL_CONFIG.settings.baseUrl}/api`,
    ];
  }
}
