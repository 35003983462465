<div class="card" [ngStyle]="{ height: height, width: width }">
  <div class="card__header header">
    <mat-icon *ngIf="icon; else titleBlock" [ngClass]="generateIconClass()">{{
      iconIdentifier
    }}</mat-icon>
    <ng-template #titleBlock>
      <h3 mat-dialog-title class="header__title">{{ title }}</h3>
    </ng-template>
    <button mat-icon-button (click)="onCloseClick()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <h3 mat-dialog-title *ngIf="icon" class="card__title">
    {{ title }}
  </h3>
  <mat-dialog-content class="card__body">
    <ng-content select="[body]"></ng-content>
  </mat-dialog-content>
  <mat-dialog-actions class="card__actions">
    <ng-content select="[actions]"></ng-content>
  </mat-dialog-actions>
</div>
