import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BaseDialogIcon } from './base-dialog-icon.enum';
import { BaseDialogResponse } from './base-dialog-response.enum';

const iconIdentifiers = {
  success: 'check_circle_outline',
  warning: 'warning',
  error: 'error_outline',
};

@Component({
  selector: 'app-base-dialog',
  templateUrl: './base-dialog.component.html',
  styleUrls: ['./base-dialog.component.scss'],
})
export class BaseDialogComponent<T> {
  @Input() title!: string;
  @Input() width!: string;
  @Input() height!: string;
  @Input() icon?: BaseDialogIcon;

  iconIdentifier: string = '';

  constructor(private dialogRef: MatDialogRef<T>) {}

  generateIconClass() {
    this.iconIdentifier = iconIdentifiers[this.icon as BaseDialogIcon];
    const iconColorClassName = this.icon ?? '';
    return {
      icon: true,
      [iconColorClassName]: true,
    };
  }

  onCloseClick(): void {
    this.close(BaseDialogResponse.CLOSE);
  }

  protected close(response: BaseDialogResponse, data?: any): void {
    this.dialogRef.close(data ? { response, data } : response);
  }
}
