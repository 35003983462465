import { Component, Inject, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseDialogIcon } from '../base-dialog/base-dialog-icon.enum';
import { BaseDialogResponse } from '../base-dialog/base-dialog-response.enum';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';

export type FormDialogData = {
  title: string;
  icon?: BaseDialogIcon;
  width: string;
  height: string;
  continueBtnText?: string;
  cancelBtnText?: string;
};

/**
 * Title and message information is passed using the MAT_DIALOG_DATA
 * When opening this dialog title and messages should be passed as dialogOptions
 */
@Component({
  selector: 'app-form-confirm-dialog',
  templateUrl: './form-confirm-dialog.component.html',
  styleUrls: ['./form-confirm-dialog.component.scss'],
})
export class FormConfirmDialogComponent extends BaseDialogComponent<FormConfirmDialogComponent> {
  @Input() formGroup: FormGroup = new FormGroup({});

  constructor(
    dialogRef: MatDialogRef<FormConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FormDialogData,
  ) {
    super(dialogRef);
  }

  public onCancelClick(): void {
    this.close(BaseDialogResponse.CANCEL);
  }

  public onContinueClick(): void {
    this.close(BaseDialogResponse.CONFIRM, { ...this.formGroup.value });
  }
}
