import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  BaseService,
  LoggingService,
  MessagingService,
} from '@sama/ngx-components';
import * as Rollbar from 'rollbar';
import { Observable, catchError } from 'rxjs';
import { TasksStats } from '../models/task/tasks-stats.model';
import { SAMA_TASKS_API_VERSION } from '../shared/api-versions';
import { RollbarService } from './rollbar-error-handler.service';

@Injectable({
  providedIn: 'root',
})
export class SamaTasksService extends BaseService {
  private baseApiUrl: string;

  constructor(
    @Inject(RollbarService)
    private rollbar: Rollbar,
    private http: HttpClient,
    messagingService: MessagingService,
    loggingService: LoggingService,
  ) {
    super(messagingService, loggingService);
    this.baseApiUrl = `${PORTAL_CONFIG.samaTasksUrl}/api/${SAMA_TASKS_API_VERSION}/projects`;
  }

  public getStats(projectId: number): Observable<TasksStats[]> {
    const url = `${this.baseApiUrl}/${projectId}/tasks/stats`;

    return this.http.get<TasksStats[]>(url).pipe(
      catchError((error) => {
        this.rollbar.error(new Error('Failed fetching tasks stats').stack);
        return this.handleError('getTasksStats')(error);
      }),
    );
  }
}
