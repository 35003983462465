<div class="container">
  <sama-app-bar
    [helpItems]="helpItems"
    [userManagerService]="userManagerService"
    [eventSender]="onAppBarItemClicked.bind(this)"
    [logoUrl]="'/projects'"
    class="app-bar"
  >
    <ng-container ngProjectAs="[centerSlot]">
      <sama-breadcrumb
        [items]="(breadCrumbConfig$ | async)!.navigation"
        [projectType]="(breadCrumbConfig$ | async)!.projectType"
        [projectStatus]="(breadCrumbConfig$ | async)!.projectStatus"
      ></sama-breadcrumb>
    </ng-container>

    <ng-container ngProjectAs="[bottomSlot]">
      <div
        *ngIf="(secondLevelConfig$ | async)!.visible"
        class="second-level-container"
      >
        <sama-second-level [title]="(secondLevelConfig$ | async)!.title">
          <ng-container ngProjectAs="[leftSlot]">
            <sama-navigate-back
              [url]="(secondLevelConfig$ | async)!.navigateBack.url"
              [label]="(secondLevelConfig$ | async)!.navigateBack.label"
            ></sama-navigate-back>
          </ng-container>
          <ng-container ngProjectAs="[rightSlot]">
            <div class="second-level-container__right">
              <sama-settings
                *ngIf="(settingsItemsConfig$ | async)!.visible"
                [label]="(settingsItemsConfig$ | async)!.label"
                [items]="(settingsItemsConfig$ | async)!.items"
              ></sama-settings>
              <button
                *ngIf="annotateButtonIsVisible$ | async"
                mat-flat-button
                color="primary"
                (click)="onAnnotateClick()"
              >
                ANNOTATE
              </button>
            </div>
          </ng-container>

          <ng-container ngProjectAs="[bottomSlot]">
            <sama-tabs [items]="(secondLevelConfig$ | async)!.tabs"></sama-tabs>
          </ng-container>
        </sama-second-level>
      </div>
    </ng-container>
  </sama-app-bar>

  <app-body body class="container__body" slot="content">
    <router-outlet></router-outlet>
  </app-body>
</div>
