<app-base-dialog
  [title]="data.title"
  [width]="data.width"
  [height]="data.height"
  [icon]="data.icon"
>
  <p class="dialog__body mat-subtitle-2" body>{{ data.message }}</p>
  <div class="dialog__actions" actions>
    <button
      cancel
      mat-flat-button
      disableRipple="true"
      (click)="onCancelClick()"
      class="action-button"
      cdkFocusRegionStart
    >
      {{ data.cancelBtnText || 'CANCEL' }}
    </button>
    <button
      continue
      mat-flat-button
      disableRipple="true"
      (click)="onContinueClick()"
      class="action-button"
    >
      {{ data.continueBtnText || 'CONTINUE' }}
    </button>
  </div>
</app-base-dialog>
