import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import {
  SamaAppBarModule,
  SamaNavigationLinksModule,
  SamaProductNameModule,
  SamaSecondLevelModule,
} from '@sama/ui-library';
import { AnnotateModule } from '../../annotate/annotate.module';
import { BodyModule } from '../body/body.module';
import { AuthenticatedLayoutComponent } from './authenticated/authenticated-layout.component';
import { EmptyLayoutComponent } from './empty/empty-layout.component';

@NgModule({
  declarations: [AuthenticatedLayoutComponent, EmptyLayoutComponent],
  exports: [AuthenticatedLayoutComponent, EmptyLayoutComponent],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    RouterModule,
    BodyModule,
    SamaNavigationLinksModule,
    SamaProductNameModule,
    SamaAppBarModule,
    SamaSecondLevelModule,
    MatButtonModule,
    AnnotateModule,
  ],
})
export class LayoutsModule {}
