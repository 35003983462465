export class ProjectStepAllocation {
  id!: number;
  teamId!: number;
  quota?: number;

  constructor(init?: Partial<ProjectStepAllocation>) {
    Object.assign(this, init);
  }
}

export class QaGroup {
  id!: number;
  name!: string;
  stepId!: number;

  constructor(init?: Partial<QaGroup>) {
    Object.assign(this, init);
  }
}

export class ProjectStep {
  id!: number;
  name!: string;
  ordinal!: number;
  stepType!: string;
  reservationExpirationSeconds!: number;
  allocations?: ProjectStepAllocation[];
  qaGroups?: QaGroup[];

  constructor(init?: Partial<ProjectStep>) {
    Object.assign(this, init);
  }
}
