import { DataLoader } from '@sama/ui-library/table';
import {
  forkJoin,
  map,
  Observable,
  ReplaySubject,
  Subject,
  switchMap,
} from 'rxjs';

import { Injectable } from '@angular/core';
import { SamaProjectsService, SamaTasksService } from '@sama/common';
import { LoadDataResult } from '@sama/ui-library';
import { Step } from '../models/step.type';

import { FormControl, FormGroup } from '@angular/forms';
import { AuthorizationService } from '@sama/angular-auth';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ProjectStepsTableDataLoaderService extends DataLoader<Step> {
  projectId = new ReplaySubject<number>(1);
  qaGroupsForm = new Subject<FormGroup>();
  qaGroupsForm$ = this.qaGroupsForm.asObservable();

  constructor(
    private projectsService: SamaProjectsService,
    private tasksService: SamaTasksService,
    private authorizationService: AuthorizationService,
  ) {
    super();
  }

  loadData(): Observable<LoadDataResult<Step>> {
    try {
      return this.projectId.pipe(
        switchMap((projectId) =>
          forkJoin({
            projectSteps: this.projectsService.getProjectSteps(projectId, [
              'qaGroups',
            ]),
            tasksStats: this.tasksService.getStats(projectId),
          }).pipe(
            switchMap(({ projectSteps, tasksStats }) => {
              const permissions = projectSteps.map((step) => ({
                action: 'work',
                resourceType: 'step',
                resourceId: `${step.id}`,
              }));

              return this.authorizationService.check$(permissions).pipe(
                map((permissionsResponse) => {
                  const projectStepsTableData = _.map(projectSteps, (step) => {
                    const taskInfo = _.find(tasksStats, { stepId: step.id });

                    const stepPermissions = _.find(permissionsResponse, {
                      resourceId: `${step.id}`,
                    });

                    return {
                      id: step.id,
                      name: step.name,
                      ordinal: step.ordinal,
                      type: step.stepType,
                      tasksStats: `${taskInfo?.availableTasks}${taskInfo?.reservedTasks ? ` (${taskInfo?.reservedTasks})` : ''}`,
                      disableStep: !stepPermissions?.allowed,
                      disableStartWorkAction:
                        taskInfo!.availableTasks === 0 &&
                        taskInfo!.reservedTasks === 0,
                      qaGroups: step.qaGroups ?? [],
                    };
                  });

                  const sortedProjectStepsTableData = _.sortBy(
                    projectStepsTableData,
                    'ordinal',
                  );

                  this.updateQAGroupsForm(sortedProjectStepsTableData);

                  return {
                    data: sortedProjectStepsTableData,
                    numResults: sortedProjectStepsTableData.length,
                  };
                }),
              );
            }),
          ),
        ),
      );
    } catch (error) {
      return new Observable<LoadDataResult<Step>>();
    }
  }

  private updateQAGroupsForm(sortedProjectStepsTableData: Step[]): void {
    const form = new FormGroup({});
    sortedProjectStepsTableData.forEach((step) => {
      form.addControl(`step-${step.id}`, new FormControl('general'));
    });

    this.qaGroupsForm.next(form);
  }
}
