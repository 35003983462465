<app-base-dialog
  width="800px"
  height="auto"
  title="Select step to get started"
  class="dialog"
>
  <div class="dialog__body" body>
    <sama-table
      [dataLoader]="dataLoader"
      [hasPagination]="false"
      [formGroup]="qaGroupsForm"
      [loadingRows]="2"
    >
      <ng-container matColumnDef>
        <th mat-header-cell *matHeaderCellDef>
          <sama-header-cell [label]="'Name'"></sama-header-cell>
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="dialog__cell" [class.disabled-cell]="element.disableStep">
            {{ element.name }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef>
        <th mat-header-cell *matHeaderCellDef>
          <sama-header-cell [label]="'Type'"></sama-header-cell>
        </th>
        <td mat-cell *matCellDef="let element">
          <div [class.disabled-cell]="element.disableStep">
            {{ element.type }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef>
        <th mat-header-cell *matHeaderCellDef>
          <sama-header-cell [label]="'Tasks'"></sama-header-cell>
        </th>
        <td mat-cell *matCellDef="let element">
          <div [class.disabled-cell]="element.disableStep">
            {{ element.tasksStats }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef>
        <th mat-header-cell *matHeaderCellDef>
          <sama-header-cell [label]="'QA Group'"></sama-header-cell>
        </th>
        <td mat-cell *matCellDef="let element">
          @if (element.type === 'QA' && !element.disableStartWorkAction) {
            <sama-select
              class="dialog__qa-groups"
              [formControlName]="getControlName(element.id)"
              [options]="getOptions(element.qaGroups)"
              [isDisabled]="element.disableStep"
              subscriptSizing="dynamic"
            >
            </sama-select>
          }
        </td>
      </ng-container>

      <ng-container matColumnDef>
        <th mat-header-cell *matHeaderCellDef>
          <sama-header-cell [label]="''"></sama-header-cell>
        </th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-flat-button
            color="primary"
            (click)="startWork(element)"
            [disabled]="element.disableStep || element.disableStartWorkAction"
          >
            START
          </button>
        </td>
      </ng-container>
    </sama-table>
  </div>
</app-base-dialog>
