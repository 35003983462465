import { PagedQueryOptions } from '@sama/ngx-components';
import { Observable, of, Subscription } from 'rxjs';
export class TasksUploaderServiceMock {
  uploadTasks(
    _projectId: number,
    _file: File,
    _skipPreProcessing: boolean,
  ): Observable<Subscription> {
    return of(new Subscription());
  }

  getUploadStatus(_projectId: number, _batchId: number) {
    return of({
      id: 1,
      status: 'completed',
      tasks: [
        {
          id: 1,
          status: 'completed',
        },
      ],
      messages: [],
      created_tasks: 1,
      total_tasks: 1,
    });
  }

  cancelUpload(_projectId: number, _batchId: number): Observable<void> {
    return of();
  }

  getUploads(_projectId: number, _pagedOptions?: PagedQueryOptions) {
    return of({
      data: mockBatchesResponse.uploads,
      count: 0,
      total: 0,
      page: 1,
      pageCount: 1,
    });
  }
}

export const mockBatchesResponse = {
  uploads: [
    {
      id: 1,
      state: 'Complete',
      message: '',
      userId: 1,
      preprocessingJobId: null,
      origin: 'upload',
      uploadErrors: [],
      createdAt: '2023-01-01T00:00:00Z',
      updatedAt: '2023-01-01T00:00:00Z',
      totalTasks: 100,
      createdTasks: 100,
      userFullName: 'user1',
    },
    {
      id: 2,
      state: 'Error',
      message: 'Some errors.',
      userId: 2,
      preprocessingJobId: null,
      origin: 'upload',
      uploadErrors: ['error1', 'error2'],
      createdAt: '2023-01-02T00:00:00Z',
      updatedAt: '2023-01-02T00:00:00Z',
      totalTasks: 50,
      createdTasks: 0,
      userFullName: 'user2',
    },
    {
      id: 3,
      state: 'Uploading',
      message: '',
      userId: 2,
      preprocessingJobId: null,
      origin: 'upload',
      uploadErrors: [],
      createdAt: '2023-01-02T00:00:00Z',
      updatedAt: '2023-01-02T00:00:00Z',
      totalTasks: 50,
      createdTasks: 1,
      userFullName: 'user2',
    },
  ],
  count: 3,
  total: 3,
  page: 1,
  pageCount: 1,
};
