import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { BaseDialogComponent } from './base-dialog/base-dialog.component';
import { BasicConfirmDialogComponent } from './basic-confirm-dialog/basic-confirm-dialog.component';
import { FormConfirmDialogComponent } from './form-confirm-dialog/form-confirm-dialog.component';
import { InfoDialogComponent } from './info-dialog/info-dialog.component';

@NgModule({
  declarations: [
    BaseDialogComponent,
    BasicConfirmDialogComponent,
    FormConfirmDialogComponent,
    InfoDialogComponent,
  ],
  exports: [
    BaseDialogComponent,
    BasicConfirmDialogComponent,
    FormConfirmDialogComponent,
    InfoDialogComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    OverlayModule,
    MatButtonModule,
    MatDialogModule,
  ],
})
export class DialogsModule {}
