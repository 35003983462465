import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { QaGroup } from '@sama/common';
import { SelectOption } from '@sama/ui-library';
import { Subscription } from 'rxjs';
import { ProjectsState } from '../../../store/projects/projects.reducer';
import { selectCurrentProjectId } from '../../../store/projects/projects.selectors';
import { BaseDialogComponent } from '../../base/dialogs/base-dialog/base-dialog.component';
import { Step } from '../models/step.type';
import { ProjectStepsTableDataLoaderService } from '../services/project-steps-table-data-loader.service';

@Component({
  selector: 'app-project-steps-dialog',
  templateUrl: './project-steps-dialog.component.html',
  styleUrls: ['./project-steps-dialog.component.scss'],
})
export class ProjectStepsDialogComponent
  extends BaseDialogComponent<ProjectStepsDialogComponent>
  implements OnInit, OnDestroy
{
  qaGroupsForm = new FormGroup({});
  subscriptions: Subscription[] = [];
  projectId!: number;

  defaultQAGroupsOptions: SelectOption<string>[] = [
    {
      label: 'General',
      value: 'general',
    },
    {
      label: 'Skipped',
      value: 'skipped',
    },
  ];

  constructor(
    dialogRef: MatDialogRef<ProjectStepsDialogComponent>,
    public dataLoader: ProjectStepsTableDataLoaderService,
    private store: Store<ProjectsState>,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    super(dialogRef);
  }

  ngOnInit(): void {
    const currentProjectId$ = this.store.select(selectCurrentProjectId);

    currentProjectId$.subscribe((projectId) => {
      if (projectId) {
        this.projectId = projectId;
        this.dataLoader.projectId.next(projectId);
      }
    });

    this.subscriptions.push(
      this.dataLoader.qaGroupsForm$.subscribe((qaGroupsForm) => {
        this.qaGroupsForm = qaGroupsForm;
        this.changeDetectorRef.detectChanges();
      }),
    );
  }

  getControlName(stepId: number): string {
    return `step-${stepId}`;
  }

  getOptions(qaGroups: QaGroup[]): SelectOption<string>[] {
    const qaGroupsOptions = qaGroups.map((qaGroup) => ({
      label: qaGroup.name,
      value: `${qaGroup.id}`,
    }));

    return [...this.defaultQAGroupsOptions, ...qaGroupsOptions];
  }

  startWork(step: Step): void {
    let url = `${PORTAL_CONFIG.samahubUrl}/projects/${this.projectId}/steps/${step.id}/tasks/work`;

    if (step.type === 'QA') {
      const stepId = step.id;
      const selectedQAGroup = this.qaGroupsForm.get(
        this.getControlName(stepId),
      )!.value;
      url = `${url}?qa_group_id=${selectedQAGroup}`;
    }

    globalThis.open(url, '_self');
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
