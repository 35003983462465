import { Permission } from '@sama/angular-auth';
import { CheckPermissionResponse } from '@sama/angular-auth/lib/authorization.service';
import { Observable, of } from 'rxjs';

export class AuthorizationServiceMock {
  isAuthorized$(_permissions: Permission[]): Observable<boolean> {
    return of(true);
  }

  check$(_permissions: Permission[]): Observable<CheckPermissionResponse[]> {
    return of([]);
  }
}
