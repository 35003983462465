import { AsyncFactoryFn, TestElement } from '@angular/cdk/testing';
import { MatButtonHarness } from '@angular/material/button/testing';
import { MatDialogHarness } from '@angular/material/dialog/testing';
import { MatIconHarness } from '@angular/material/icon/testing';

export class BaseDialogHarness extends MatDialogHarness {
  private getCloseButtonHarness: AsyncFactoryFn<MatButtonHarness> =
    this.locatorFor(MatButtonHarness);

  private getTitleElement: AsyncFactoryFn<TestElement> = this.locatorFor('h3');

  private getCardElement: AsyncFactoryFn<TestElement> =
    this.locatorFor('.card');

  private getBodyElement: AsyncFactoryFn<TestElement> =
    this.locatorFor('p[body]');

  private getActionsElement: AsyncFactoryFn<TestElement> =
    this.locatorFor('p[actions]');

  public async getTitleText(): Promise<string> {
    const title = await this.getTitleElement();
    return title.text();
  }

  public async clickClose(): Promise<void> {
    const closeButton = await this.getCloseButtonHarness();
    return closeButton.click();
  }

  public async getHeight(): Promise<string> {
    const cardElement = await this.getCardElement();
    return cardElement.getCssValue('height');
  }

  public async getWidth(): Promise<string> {
    const cardElement = await this.getCardElement();
    return cardElement.getCssValue('width');
  }

  public async getBody(): Promise<string> {
    const body = await this.getBodyElement();
    return body.text();
  }

  public async getActions(): Promise<string> {
    const actions = await this.getActionsElement();
    return actions.text();
  }

  public async getIconByName(name: string): Promise<string | null> {
    return await (
      await this.locatorFor(MatIconHarness.with({ name: name }))()
    ).getName();
  }
}
