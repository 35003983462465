import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import {
  BaseService,
  FindPageResult,
  LoggingService,
  MessagingService,
} from '@sama/ngx-components';
import * as Rollbar from 'rollbar';
import { Observable, catchError, of, switchMap, tap } from 'rxjs';
import { ProjectInput } from '../models/project/project-inputs.interface';
import { ProjectOutput } from '../models/project/project-output.model';
import { ProjectStep } from '../models/project/project-step.model';
import { Project } from '../models/project/project.model';
import { SAMA_PROJECTS_API_VERSION } from '../shared/api-versions';
import { RollbarService } from './rollbar-error-handler.service';

export interface UpdateProjectBody {
  visualizationEnabled: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class SamaProjectsService extends BaseService {
  private baseApiUrl: string;

  constructor(
    @Inject(RollbarService)
    private rollbar: Rollbar,
    private http: HttpClient,
    messagingService: MessagingService,
    loggingService: LoggingService,
  ) {
    super(messagingService, loggingService);
    this.baseApiUrl = `${PORTAL_CONFIG.samaProjectsUrl}/api/${SAMA_PROJECTS_API_VERSION}/projects`;
  }

  public getProject(projectId: number): Observable<Project> {
    const url = `${this.baseApiUrl}/${projectId}`;
    return this.http.get<Project>(url).pipe(
      tap((_) => this.logSuccess(`fetched sama project`)),
      catchError((error) => {
        this.rollbar.error(new Error('Failed fetching a sama project').stack);
        return this.handleError('getProject')(error);
      }),
    );
  }

  public updateProject(
    projectId: number,
    body: UpdateProjectBody,
  ): Observable<Project> {
    const url = `${this.baseApiUrl}/${projectId}`;
    return this.http.patch<Project>(url, body).pipe(
      tap((_) => this.logSuccess(`update on sama project`)),
      catchError((error) => {
        this.rollbar.error(new Error('Failed patching at sama project').stack);
        return this.handleError('updateProject')(error);
      }),
    );
  }

  public getProjectOutputs(projectId: number): Observable<ProjectOutput[]> {
    const url = `${this.baseApiUrl}/${projectId}/outputs`;
    return this.http.get<FindPageResult<ProjectOutput>>(`${url}`).pipe(
      tap((_) => this.logSuccess(`fetched sama project`)),
      switchMap((res) => of(res.data)),
      catchError((error) => {
        this.rollbar.error(new Error('Failed fetching a sama project').stack);
        return this.handleError('getProject')(error);
      }),
    );
  }

  public getProjectSteps(
    projectId: number,
    joins?: string[],
  ): Observable<ProjectStep[]> {
    let url = `${this.baseApiUrl}/${projectId}/steps`;

    joins?.forEach((join) => {
      url = url.concat(`?join=${join}`);
    });

    return this.http.get<ProjectStep[]>(url).pipe(
      tap((_) => this.logSuccess(`fetched project steps`)),
      catchError((error) => {
        this.rollbar.error(new Error('Failed fetching project steps').stack);
        return this.handleError('getProjectSteps')(error);
      }),
    );
  }

  public getProjectInputs(projectId: number): Observable<ProjectInput[]> {
    const url = `${this.baseApiUrl}/${projectId}/inputs`;
    return this.http.get<FindPageResult<ProjectInput>>(url, {}).pipe(
      tap((_) => this.logSuccess(`fetched inputs from project`)),
      switchMap((res) => of(res.data)),
      catchError((error) => {
        this.rollbar.error(
          new Error('Failed getting input from project').stack,
        );
        return this.handleError('getInputs')(error);
      }),
    );
  }
}
