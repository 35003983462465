import { AsyncFactoryFn, TestElement } from '@angular/cdk/testing';
import { MatButtonHarness } from '@angular/material/button/testing';
import { BaseDialogHarness } from '../base-dialog/base-dialog.harness';

export class BasicConfirmDialogHarness extends BaseDialogHarness {
  private getCancelBtnHarness: AsyncFactoryFn<TestElement> =
    this.locatorFor('[cancel]');

  private getContinueBtnHarness: AsyncFactoryFn<TestElement> =
    this.locatorFor('[continue]');

  public async clickCancel(): Promise<void> {
    const cancelButton = await this.getCancelBtnHarness();
    return cancelButton.click();
  }

  public async getCancelText(): Promise<string> {
    const cancelButton = await this.getCancelBtnHarness();
    return cancelButton.text();
  }

  public async clickContinue(): Promise<void> {
    const continueButton = await this.getContinueBtnHarness();
    return continueButton.click();
  }

  public async getContinueText(): Promise<string> {
    const continueButton = await this.getContinueBtnHarness();
    return continueButton.text();
  }

  public async click(buttonText: string): Promise<void> {
    const button = await this.locatorFor(
      MatButtonHarness.with({ text: buttonText }),
    )();
    return button.click();
  }
}
