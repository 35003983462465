import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AnnotateButtonService {
  private isVisible = new BehaviorSubject<boolean>(false);

  public isVisible$ = this.isVisible.asObservable();

  async updateVisibility(hasFlag: boolean) {
    this.isVisible.next(hasFlag);
  }
}
